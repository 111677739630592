'use strict';

angular.module('app').component('pedido', {
  templateUrl: 'pedido.html',
  controller(Pedidos, $routeParams, $window) {
    this.$onInit = () => {
      Pedidos.get($routeParams.id).then((pedido) => {
        this.pedido = pedido;
      });
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este pedido?')) {
        Pedidos.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.changeStatus = (newStatus) => {
      Pedidos.save(
        Object.assign({}, this.pedido, {
          status: newStatus,
        })
      ).then((pedido) => {
        this.pedido = pedido;
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.verificar = () => {
      Pedidos.verificar(this.pedido._id).then((pedido) => {
        this.pedido = pedido;
      });
    };
  },
});
