import './cupom-desconto';
import './cupons-desconto.service';
import './editar-cupom-desconto';

angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider
      .when('/cupons-desconto', {
        template: '<cupons-desconto>',
      })
      .when('/cupons-desconto/novo', {
        template: '<editar-cupom-desconto>',
      })
      .when('/cupons-desconto/:id', {
        template: '<cupom-desconto>',
      })
      .when('/cupons-desconto/:id/editar', {
        template: '<editar-cupom-desconto>',
      });
  })
  .component('cuponsDesconto', {
    templateUrl: 'cupons-desconto.html',
    controller(CuponsDesconto, $location, $filter) {
      this.$onInit = () => {
        this.carregando = true;
        this.cupons = [];
        this.cuponsVisualizados = [];
        this.cuponsFiltrados = [];
        this.ordenacao = 'nome';
        this.query();
      };

      this.query = () => {
        CuponsDesconto.query().then((cupons) => {
          this.cupons = $filter('orderBy')(cupons, this.ordenacao);
          this.carregando = false;
          this.cuponsFiltrados = this.cupons;
        });
      };

      this.novo = () => {
        $location.path('/cupons-desconto/novo');
      };

      this.exibirFiltros = () => {
        this.exibeFiltros = !this.exibeFiltros;
      };

      this.atualizarFiltros = () => {
        this.cuponsFiltrados = $filter('filter')(this.cupons, {
          nome: this.filtroNome || undefined,
          codigo: this.filtroCodigo || undefined,
        });
      };

      this.ordenar = () => {
        this.cupons = $filter('orderBy')(this.cupons, this.ordenacao);
        this.atualizarFiltros();
      };
    },
  });
