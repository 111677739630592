'use strict';

import './modelos.service';
import './editar-modelo';
import './modelo';
import './importacao/importacao';
import './exibir-svg';
import './editar-grupos-subgrupos/editar-grupos-subgrupos';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/modelos', {
      template: '<modelos>',
    })
    .when('/modelos/novo', {
      template: '<editar-modelo>',
    })
    .when('/modelos/importar-svg', {
      template: '<importacoes>',
    })
    .when('/modelos/:id', {
      template: '<modelo>',
    })
    .when('/modelos/:id/editar', {
      template: '<editar-modelo>',
    });
});

m.component('modelos', {
  templateUrl: 'modelos.html',
  controller(Models, $location, $filter) {
    this.$onInit = () => {
      this.carregando = true;
      this.modelos = [];
      this.modelosVisualizados = [];
      this.modelosFiltrados = [];
      this.ordenacao = 'tipo.name';

      Models.query().then((modelos) => {
        this.modelos = $filter('orderBy')(modelos, this.ordenacao);
        this.carregando = false;
        this.modelosFiltrados = this.modelos;
      });
    };

    this.novo = () => {
      $location.path('modelos/novo');
    };

    this.importarSVG = () => {
      $location.path('modelos/importar-svg');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.modelosFiltrados = $filter('filter')(this.modelos, {
        tipo: { name: this.filtroTipo || undefined },
        code: this.filtroCodigo || undefined,
        custom_fabric_code: this.filtroTecido || undefined,
      });
    };

    this.ordenar = () => {
      this.modelos = $filter('orderBy')(this.modelos, this.ordenacao);
      this.atualizarFiltros();
    };
  },
});
