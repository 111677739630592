angular.module('app').component('editarCupomDesconto', {
  templateUrl: 'editar-cupom-desconto.html',
  controller(CuponsDesconto, $window, $routeParams) {
    this.$onInit = () => {
      this.cupom = {};

      if ($routeParams.id) {
        CuponsDesconto.get($routeParams.id).then((cupom) => {
          this.cupom = cupom;
        });
      }
    };

    this.salvar = () => {
      CuponsDesconto.save(this.cupom).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
