'use strict';

angular.module('app').service('Administradores', function ($http) {
  this.query = () => $http.get('/api/admins').then(({ data }) => data);

  this.save = (administrador) => {
    const id = administrador._id;

    return $http.post(`/api/admins/${id || ''}`, administrador).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/admins/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/admins/${id}`).then(({ data }) => data);
});
