'use strict';

angular.module('app').service('Pedidos', function ($http) {
  this.query = (usuarioId) => {
    let url = '/api/pedidos';

    if (usuarioId) {
      url += `?client=${usuarioId}`;
    }

    return $http.get(url).then(({ data }) => data);
  };

  this.save = (pedido) => {
    const id = pedido._id;

    return $http.post(`/api/pedidos/${id || ''}`, pedido).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/pedidos/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/pedidos/${id}`).then(({ data }) => data);

  this.verificar = (id) => $http.post(`/api/pedidos/${id}/verificar`).then(({ data }) => data);
});
