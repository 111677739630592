'use strict';

import './pedido.js';
import './pedidos.service.js';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/pedidos', {
      template: '<pedidos>',
    })
    .when('/pedidos/:id', {
      template: '<pedido>',
    });
});

m.component('pedidos', {
  templateUrl: 'pedidos.html',
  controller(Pedidos, $location, $filter) {
    this.$onInit = () => {
      this.filtroStatus = 'abertas';
      this.orderFilter = {};
      this.carregando = true;
      this.pedidos = [];
      this.pedidosVisualizados = [];
      this.pedidosFiltrados = [];
      this.query();
    };

    this.query = () => {
      Pedidos.query().then((pedidos) => {
        this.pedidos = $filter('orderBy')(pedidos, '-createdAt');
        this.carregando = false;
        this.atualizarFiltros();
      });
    };

    this.orderFilterCpm = (actual) => {
      switch (this.filtroStatus) {
        case 'abertas':
          return !/(cancelada|concluída)/.test(actual.status);

        case 'concluídas':
          return /concluída/.test(actual.status);

        case 'canceladas':
          return /cancelada/.test(actual.status);

        case 'todas':
        default:
          return true;
      }
    };

    this.novo = () => {
      $location.path('pedidos/novo');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.pedidosFiltrados = $filter('filter')(this.pedidos, {
        client: { name: this.filtroCliente || undefined },
        createdAt: this.filtroData || undefined,
        total: this.filtroTotal || undefined,
        shippingType: this.filtroTipoFrete || undefined,
      });

      this.pedidosFiltrados = $filter('filter')(this.pedidosFiltrados, this.orderFilterCpm.bind(this));
    };
  },
});
