'use strict';

angular.module('app').component('editarAmostraTecido', {
  templateUrl: 'editar-amostra-tecido.html',
  controller(AmostrasTecido, Fabrics, FabricantesTecido, $routeParams, $window, $filter, $location) {
    this.$onInit = () => {
      this.tiposPreenchimento = [
        {
          label: 'Blocos',
          value: 'blocos',
        },
        {
          label: 'Completo',
          value: 'completo',
        },
      ];

      const { id } = $routeParams;

      this.amostra = {};
      if (id) {
        AmostrasTecido.get(id).then((amostra) => {
          this.amostra = amostra;
        });
      }

      Fabrics.query().then((tecidos) => {
        this.tecidos = $filter('orderBy')(tecidos, 'name');
      });

      FabricantesTecido.query().then((fabricantes) => {
        this.fabricantes = $filter('orderBy')(fabricantes, 'name');
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.salvar = () => {
      AmostrasTecido.save(this.amostra).then((amostra) => {
        $location.path(`amostras-tecido/${amostra._id}`);
      });
    };

    this.filtrarSubgrupos = () => {
      if (!this.amostra.group_id) {
        return;
      }

      this.subgruposDisponiveis = this.subgrupos.filter((subgrupo) =>
        this.amostra.group_id.includes(subgrupo.group_id)
      );
    };
  },
});
