'use strict';

angular.module('app').directive('fileUpload', () => ({
  restrict: 'A',
  scope: {
    onSelect: '&fileUpload',
  },
  link(scope, element) {
    element.bind('change', () => {
      scope.onSelect({
        files: element[0].files,
      });
    });
  },
}));
