'use strict';

angular.module('app').component('tabelaMedidas', {
  templateUrl: 'tabela-medidas.html',
  controller(MeasuresTables, $routeParams, $location, $window) {
    this.$onInit = () => {
      MeasuresTables.get($routeParams.id).then((tabela) => {
        this.tabela = tabela;
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.excluir = () => {
      if (confirm('Deseja excluir esta tabela de medidas?')) {
        MeasuresTables.remove(this.tabela._id).then(() => {
          this.voltar();
        });
      }
    };

    this.editar = () => {
      $location.path(`/tabelas-medidas/${this.tabela._id}/editar`);
    };

    this.removeMedida = (medida) => {
      this.tabela.measures.splice(this.tabela.measures.indexOf(medida), 1);
    };
  },
});
