angular.module('app').service('Models', function ($http, $httpParamSerializer, $q) {
  this.query = (filtros) =>
    $http
      .get(`/api/models${Object.keys(filtros || {}).length > 0 ? `?${$httpParamSerializer(filtros)}` : ''}`)
      .then(({ data }) => data);

  this.save = (model) => {
    const id = model._id;

    return $http
      .post(`/api/models/${id || ''}`, {
        ...model,
        subgroups: null,
        groups: null,
        tipo: null,
        unfoldings: undefined,
      })
      .then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/models/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/models/${id}`).then(({ data }) => data);

  this.importar = (svg) =>
    $http
      .post('/api/importations', svg, {
        headers: { 'Content-type': undefined },
      })
      .then(({ data }) => data);

  this.saveVariation = (variacao) => {
    let promise = $q.resolve(variacao.modelo);

    if (!variacao.modelo._id) {
      promise = this.save(variacao.modelo);
    }

    return promise
      .then((modelo) => {
        const data = {
          ...variacao,
          modelo: null,
          groups: null,
          subgroups: null,
          subgruposDisponiveis: null,
          modelType: null,
          variacaoExistente: variacao.variacaoExistente && {
            _id: variacao.variacaoExistente._id,
          },
        };

        return $http.post(`/api/models/${modelo._id}/variations`, data);
      })
      .then(({ data }) => data);
  };
});
