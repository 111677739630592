'use strict';

angular.module('app').component('amostraTecido', {
  templateUrl: 'amostra-tecido.html',
  controller(AmostrasTecido, $routeParams, $location, $window) {
    this.$onInit = () => {
      this.get();
    };

    this.get = () => {
      AmostrasTecido.get($routeParams.id).then((amostra) => {
        this.amostra = amostra;
      });
    };

    this.editar = () => {
      $location.path(`amostras-tecido/${this.amostra._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir esta amostra de tecido?')) {
        AmostrasTecido.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.incluiImagem = (arquivos) => {
      this.carregandoFoto = true;
      const formData = new FormData();

      formData.append('imagemOriginal', arquivos[0], arquivos[0].name);

      AmostrasTecido.uploadImagem(this.amostra._id, formData).then(() => {
        this.carregandoFoto = false;
        this.get();
      });
    };

    this.removeImagem = () => {
      AmostrasTecido.deleteImagem(this.amostra._id).then(() => {
        this.get();
      });
    };

    this.toggleHabilitar = () => {
      this.amostra.disabled = !this.amostra.disabled;
      AmostrasTecido.save(this.amostra);
    };
  },
});
