angular.module('app').component('cupomDesconto', {
  templateUrl: 'cupom-desconto.html',
  controller(CuponsDesconto, $routeParams, $window, $location) {
    this.$onInit = () => {
      CuponsDesconto.get($routeParams.id).then((cupom) => {
        this.cupom = cupom;
      });
    };

    this.editar = () => {
      $location.path(`/cupons-desconto/${this.cupom._id}/editar`);
    };

    this.excluir = () => {
      CuponsDesconto.remove(this.cupom._id).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
