'use strict';

import './editar-administrador.js';
import './administrador.js';
import './administradores.service.js';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/administradores', {
      template: '<administradores>',
    })
    .when('/administradores/novo', {
      template: '<editar-administrador>',
    })
    .when('/administradores/:id', {
      template: '<administrador>',
    })
    .when('/administradores/:id/editar', {
      template: '<editar-administrador>',
    });
});

m.component('administradores', {
  templateUrl: 'administradores.html',
  controller(Administradores, $location, $filter) {
    this.$onInit = () => {
      this.carregando = true;
      this.administradores = [];
      this.administradoresVisualizados = [];
      this.administradoresFiltrados = [];
      this.ordenacao = 'email';
      this.query();
    };

    this.query = () => {
      Administradores.query().then((administradores) => {
        this.administradores = $filter('orderBy')(administradores, this.ordenacao);
        this.carregando = false;
        this.administradoresFiltrados = this.administradores;
      });
    };
    this.novo = () => {
      $location.path('administradores/novo');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.administradoresFiltrados = $filter('filter')(this.administradores, {
        email: this.filtroEmail || undefined,
      });
    };

    this.ordenar = () => {
      this.administradores = $filter('orderBy')(this.administradores, this.ordenacao);
      this.atualizarFiltros();
    };
  },
});
