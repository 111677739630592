'use strict';

angular.module('app').component('fabricante', {
  templateUrl: 'fabricante.html',
  controller(FabricantesTecido, $routeParams, $location, $window) {
    this.$onInit = () => {
      this.get();
    };

    this.get = () => {
      FabricantesTecido.get($routeParams.id).then((fabricante) => {
        this.fabricante = fabricante;
      });
    };

    this.editar = () => {
      $location.path(`fabricantes/${this.fabricante._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este fabricante?')) {
        FabricantesTecido.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.incluiLogotipo = (arquivos) => {
      this.carregandoFoto = true;
      const formData = new FormData();

      formData.append('logotipo', arquivos[0], arquivos[0].name);

      FabricantesTecido.uploadLogotipo(this.fabricante, formData).then(() => {
        this.carregandoFoto = false;
        this.get();
      });
    };

    this.removeLogotipo = () => {
      FabricantesTecido.deleteLogotipo(this.fabricante).then(() => {
        this.get();
      });
    };
  },
});
