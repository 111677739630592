'use strict';

angular.module('app').component('editarTabelaMedidas', {
  templateUrl: 'editar-tabela-medidas.html',
  controller(MeasuresTables, $routeParams, $window) {
    this.$onInit = () => {
      this.tabela = {
        measures: [],
      };
      this.showInput = false;

      if ($routeParams.id) {
        MeasuresTables.get($routeParams.id).then((tabela) => {
          this.tabela = tabela;
        });
      }
    };

    this.salvar = () => {
      if (this.exibirFormulario) {
        if (this.editMode) {
          this.tabela.measures[this.index] = this.medida;
        } else {
          this.tabela.measures.push(this.medida);
        }

        this.toggleFormulario();
        this.editMode = false;
        this.medida = {};

        return;
      }

      MeasuresTables.save(this.tabela).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.removeMedida = (index) => {
      this.tabela.measures.splice(index, 1);
    };

    this.editarMedida = (index) => {
      this.editMode = true;
      this.index = index;
      this.medida = Object.assign({}, this.tabela.measures[index]);
      this.toggleFormulario();
    };

    this.toggleFormulario = () => {
      this.exibirFormulario = !this.exibirFormulario;
    };
  },
});
