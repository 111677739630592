angular.module('app').service('ModelTypes', function ($http) {
  this.query = () => $http.get('api/model-types').then(({ data }) => data);

  this.get = (id) => $http.get(`/api/model-types/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/model-types/${id}`).then(({ data }) => data);

  this.save = (modelType) => {
    const { _id } = modelType;

    return $http.post(`/api/model-types/${_id || ''}`, modelType).then(({ data }) => data);
  };
});
