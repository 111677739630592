'use strict';

angular.module('app').service('FabricSubgroups', function ($http, $httpParamSerializer) {
  this.query = (filtros) =>
    $http
      .get(`/api/fabric-subgroups${Object.keys(filtros || {}).length > 0 ? `?${$httpParamSerializer(filtros)}` : ''}`)
      .then(({ data }) => data);

  this.save = (fabricSubgroup) => {
    const id = fabricSubgroup._id;

    return $http
      .post(`/api/fabric-subgroups/${id || ''}`, {
        ...fabricSubgroup,
        models: null,
        group: null,
      })
      .then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/fabric-subgroups/${id || ''}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/fabric-subgroups/${id}`).then(({ data }) => data);
});
