'use strict';

angular.module('app').directive('validationError', () => ({
  restrict: 'A',
  require: 'ngModel',
  link(scope, elem, attrs) {
    scope.$watch(attrs.validationError, (errMsg) => {
      if (elem[0] && elem[0].setCustomValidity) {
        elem[0].setCustomValidity(errMsg);
      }
    });
  },
}));
