'use strict';

angular.module('app').component('usuario', {
  templateUrl: 'usuario.html',
  controller(Usuarios, Pedidos, $routeParams, $location, $window, $filter) {
    this.$onInit = () => {
      this.carregandoPedidos = true;
      Usuarios.get($routeParams.id).then((usuario) => {
        this.usuario = usuario;
      });

      Pedidos.query($routeParams.id).then((pedidos) => {
        this.pedidos = $filter('orderBy')(pedidos, '-createdAt');
        this.carregandoPedidos = false;
      });
    };

    this.editar = () => {
      $location.path(`usuarios/${this.usuario._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este usuário?')) {
        Usuarios.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
