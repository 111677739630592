'use strict';

angular.module('app').component('editarFabricante', {
  templateUrl: 'editar-fabricante.html',
  controller(FabricantesTecido, $routeParams, $window) {
    this.$onInit = () => {
      const { id } = $routeParams;

      this.fabricante = {};
      if (id) {
        FabricantesTecido.get(id).then((fabricante) => {
          this.fabricante = fabricante;
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.salvar = () => {
      FabricantesTecido.save(this.fabricante).then(() => {
        this.voltar();
      });
    };
  },
});
