'use strict';

angular.module('app').filter(
  'telefone',
  ($filter) =>
    function (phone) {
      if (!phone) {
        return '';
      }

      const valorLimpo = removeEspacos(phone);
      const [phoneNumber, areaCode, countryCode] = valorLimpo
        .split(/(.{2})?(.{2})?(.{8,9})$/)
        .filter((s) => Boolean(s))
        .reverse();

      return `${countryCode ? `+${countryCode}` : ''} ${$filter('brPhoneNumber')(`${areaCode || ''}${phoneNumber}`)}`;
    }
);

function removeEspacos(phone) {
  return phone.replace(/[^0-9]/g, '');
}
