'use strict';

angular.module('app').component('editarGrupoTecido', {
  templateUrl: 'editar-grupo-tecido.html',
  controller(FabricGroups, $routeParams, $window) {
    this.$onInit = () => {
      this.grupoTecido = {};

      if ($routeParams.id) {
        FabricGroups.get($routeParams.id).then((grupoTecido) => {
          this.grupoTecido = grupoTecido;
        });
      }
    };

    this.salvar = () => {
      FabricGroups.save(this.grupoTecido).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
