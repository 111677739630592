'use strict';

angular.module('app').component('appSteppers', {
  template: `
		<ul>
			<li ng-repeat="step in $ctrl.steps" ng-class="{'with-line': $index > 0, 'active':$ctrl.isActive($index)}">
				<span class="step-line" ng-if="$index > 0"></span>
				<span class="step">
					<span class="step-number">
						{{$index + 1}}
					</span>
					<span class="step-caption">{{step}}</span>
				</span>
			</li>
		</ul>
	`,
  bindings: {
    steps: '<',
    actualStep: '<',
  },
  controller() {
    const findActiveStepIndex = () => {
      this.steps.some((step, index) => {
        if (step === this.actualStep) {
          this.activeStepIndex = index;

          return true;
        }

        return false;
      });
    };

    this.$onChanges = () => {
      findActiveStepIndex();
    };

    this.isCompleted = (index) => index < this.activeStepIndex;

    this.isActive = (index) => index <= this.activeStepIndex;
  },
});
