'use strict';

angular.module('app').component('editarUsuario', {
  templateUrl: 'editar-usuario.html',
  controller(Usuarios, $routeParams, $window) {
    this.$onInit = () => {
      const { id } = $routeParams;

      this.usuario = {};
      if (id) {
        Usuarios.get(id).then((usuario) => {
          this.usuario = usuario;
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.salvar = () => {
      Usuarios.save(this.usuario).then(() => {
        this.voltar();
      });
    };
  },
});
