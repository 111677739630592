'use strict';

angular.module('app').component('importacaoModelo', {
  bindings: {
    importacao: '<',
    grupos: '<',
    subgrupos: '<',
  },
  templateUrl: 'importacao-modelo.html',
  controller() {
    this.$onInit = () => {
      this.importacao.habilitada = true;
    };
  },
});
