import './configuracoes-app.service';
import './editar-configuracoes-app';

angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider
      .when('/configuracoes-app/', {
        template: '<configuracoes-app>',
      })
      .when('/configuracoes-app/editar', {
        template: '<editar-configuracoes-app>',
      });
  })
  .component('configuracoesApp', {
    templateUrl: 'configuracoes-app.html',
    controller(ConfiguracoesAppService, $window, $location) {
      this.$onInit = () => {
        ConfiguracoesAppService.get().then((configuracoes) => {
          this.configuracoes = configuracoes;
        });
      };

      this.editar = () => {
        $location.path('/configuracoes-app/editar');
      };

      this.voltar = () => {
        $window.history.back();
      };
    },
  });
