import './tipos-modelo.service';
import './tipo-modelo';
import './editar-tipo-modelo';

angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider
      .when('/tipos-modelo', {
        template: '<tipos-modelo>',
      })
      .when('/tipos-modelo/novo', {
        template: '<editar-tipo-modelo>',
      })
      .when('/tipos-modelo/:id', {
        template: '<tipo-modelo>',
      })
      .when('/tipos-modelo/:id/editar', {
        template: '<editar-tipo-modelo>',
      });
  })
  .component('tiposModelo', {
    templateUrl: 'tipos-modelo.html',
    controller(ModelTypes, $location, $filter) {
      this.$onInit = () => {
        this.carregando = true;
        this.tiposModelo = [];
        this.tiposModeloVisualizados = [];
        this.tiposModeloFiltrados = [];
        this.ordenacao = 'code';
        this.query();
      };

      this.query = () => {
        ModelTypes.query().then((tiposModelo) => {
          this.tiposModelo = $filter('orderBy')(tiposModelo, this.ordenacao);
          this.carregando = false;
          this.tiposModeloFiltrados = this.tiposModelo;
        });
      };

      this.novo = () => {
        $location.path('/tipos-modelo/novo');
      };

      this.exibirFiltros = () => {
        this.exibeFiltros = !this.exibeFiltros;
      };

      this.atualizarFiltros = () => {
        this.tiposModeloFiltrados = $filter('filter')(this.tiposModelo, {
          name: this.filtroNome || undefined,
          order: this.filtroOrdem || undefined,
          code: this.filtroCodigo || undefined,
        });
      };

      this.ordenar = () => {
        this.tiposModelo = $filter('orderBy')(this.tiposModelo, this.ordenacao);
        this.atualizarFiltros();
      };
    },
  });
