'use strict';

import './editar-variacao-modelo.js';
import './variacao-modelo.js';
import './variacoes-modelo.service.js';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/variacoes-modelo', {
      template: '<variacoes-modelo>',
    })
    .when('/variacoes-modelo/novo', {
      template: '<editar-variacao-modelo>',
    })
    .when('/variacoes-modelo/:id', {
      template: '<variacao-modelo>',
    })
    .when('/variacoes-modelo/:id/editar', {
      template: '<editar-variacao-modelo>',
    });
});

m.component('variacoesModelo', {
  templateUrl: 'variacoes-modelo.html',
  controller(VariacoesModelo, $location, $filter) {
    this.$onInit = () => {
      this.carregando = true;
      this.variacoes = [];
      this.variacoesVisualizadas = [];
      this.variacoesFiltradas = [];
      this.ordenacao = 'code';
      this.query();
    };

    this.query = () => {
      VariacoesModelo.query().then((variacoes) => {
        this.variacoes = $filter('orderBy')(variacoes, this.ordenacao);
        this.carregando = false;
        this.variacoesFiltradas = this.variacoes;
      });
    };

    this.novo = () => {
      $location.path('variacoes-modelo/novo');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.variacoesFiltradas = $filter('filter')(this.variacoes, {
        name: this.filtroNome || undefined,
        modelType: { name: this.filtroTipo } || undefined,
        code: this.filtroCodigo || undefined,
      });
    };

    this.ordenar = () => {
      this.variacoes = $filter('orderBy')(this.variacoes, this.ordenacao);
      this.atualizarFiltros();
    };
  },
});
