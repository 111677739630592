'use strict';

import './lead';
import './leads.service';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/leads', {
      template: '<leads>',
    })
    .when('/leads/:id', {
      template: '<lead>',
    });
});

m.component('leads', {
  templateUrl: 'leads.html',
  controller(Leads, $filter, $location) {
    this.$onInit = () => {
      this.carregando = true;
      this.leads = [];
      this.leadsVisualizados = [];
      this.leadsFiltrados = [];
      this.ordenacao = '-updatedAt';
      this.query();
    };

    this.query = () => {
      Leads.query().then((leads) => {
        this.leads = leads;
        this.carregando = false;
        this.ordenar();
      });
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.leadsFiltrados = $filter('filter')(this.leads, {
        name: this.filtroNome || undefined,
        email: this.filtroEmail || undefined,
        company: this.filtroEmpresa || undefined,
        role: this.filtroCargo || undefined,
        createdAt: this.filtroCadastro || undefined,
        updatedAt: this.filtroInteracao || undefined,
      });
    };

    this.ordenar = () => {
      this.leads = $filter('orderBy')(this.leads, this.ordenacao);

      if (['-createdAt', '-updatedAt'].includes(this.ordenacao)) {
        this.leads.sort(({ [this.ordenacao.slice(1)]: a }, { [this.ordenacao.slice(1)]: b }) => {
          if (a === undefined && b !== undefined) {
            return 1;
          }

          if (a === undefined && b === undefined) {
            return 0;
          }

          if (a !== undefined && b === undefined) {
            return -1;
          }

          if (a > b) {
            return -1;
          }

          if (a < b) {
            return 1;
          }

          return 0;
        });
      }

      this.atualizarFiltros();
    };

    this.exibirDetalhes = (lead) => {
      $location.path(`/leads/${lead._id}`);
    };
  },
});
