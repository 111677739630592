'use strict';

angular.module('app').service('FabricantesTecido', function ($http) {
  this.query = () => $http.get('/api/fabricantes').then(({ data }) => data);

  this.save = (fabricante) => {
    const id = fabricante._id;

    return $http.post(`/api/fabricantes/${id || ''}`, fabricante).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/fabricantes/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/fabricantes/${id}`).then(({ data }) => data);

  this.uploadLogotipo = (fabricante, logotipo) =>
    $http
      .post(`/api/fabricantes/${fabricante._id}/logotipo`, logotipo, {
        headers: { 'Content-type': undefined },
      })
      .then(({ data }) => data);

  this.deleteLogotipo = (fabricante) =>
    $http.delete(`/api/fabricantes/${fabricante._id}/logotipo`).then(({ data }) => data);
});
