'use strict';

angular.module('app').service('MeasuresTables', function ($http) {
  this.query = () => $http.get('/api/measures-table').then(({ data }) => data);

  this.save = (measuresTable) => {
    const id = measuresTable._id;

    return $http.post(`/api/measures-table/${id || ''}`, measuresTable).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/measures-table/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/measures-table/${id}`).then(({ data }) => data);
});
