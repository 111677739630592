'use strict';

import './subgrupos-tecido.service';
import './subgrupo-tecido';
import './editar-subgrupo-tecido';

angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider
      .when('/subgrupos-tecido', {
        template: '<subgrupos-tecido>',
      })
      .when('/subgrupos-tecido/novo', {
        template: '<editar-subgrupo-tecido>',
      })
      .when('/subgrupos-tecido/:id/editar', {
        template: '<editar-subgrupo-tecido>',
      })
      .when('/subgrupos-tecido/:id', {
        template: '<subgrupo-tecido>',
      });
  })
  .component('subgruposTecido', {
    templateUrl: 'subgrupos-tecido.html',
    controller(FabricSubgroups, $location, $filter) {
      this.$onInit = () => {
        this.carregando = true;
        this.subgruposTecido = [];
        this.subgruposTecidoVisualizados = [];
        this.subgruposTecidoFiltrados = [];
        this.ordenacao = 'group.name';
        this.query();
      };

      this.query = () => {
        FabricSubgroups.query().then((subgruposTecido) => {
          this.subgruposTecido = $filter('orderBy')(subgruposTecido, this.ordenacao);
          this.carregando = false;
          this.subgruposTecidoFiltrados = this.subgruposTecido;
        });
      };

      this.novo = () => {
        $location.path('/subgrupos-tecido/novo');
      };

      this.exibirFiltros = () => {
        this.exibeFiltros = !this.exibeFiltros;
      };

      this.atualizarFiltros = () => {
        this.subgruposTecidoFiltrados = $filter('filter')(this.subgruposTecido, {
          name: this.filtroNome || undefined,
          group: { name: this.filtroGrupo } || undefined,
        });
      };

      this.ordenar = () => {
        this.subgruposTecido = $filter('orderBy')(this.subgruposTecido, this.ordenacao);
        this.atualizarFiltros();
      };
    },
  });
