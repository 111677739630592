angular.module('app').component('paginacao', {
  template: `
		<div class="d-flex  justify-content-end align-items-center px-3">
			<div class="p-2">
				<div class="form-inline">
					<div><strong>Itens por página</strong></div>
					<select class="form-control form-control-sm ml-3" ng-model="$ctrl.itensPorPagina" ng-change="$ctrl.exibirItens()">
						<option ng-value="5">5</option>
						<option ng-value="10">10</option>
						<option ng-value="25">25</option>
						<option ng-value="100">100</option>
					</select>
				</div>
			</div>

			<div class="p-2"><strong>
				{{$ctrl.start + 1}} - {{$ctrl.itensVisualizados.length +$ctrl.start}} de {{$ctrl.itens.length}}
			</strong></div>

			<div class="p-2 mb-0">
				<a href="" ng-click="$ctrl.voltar()"><i class="fas fa-chevron-left fa-lg text-white" title="Página anterior"></i></a>
				<a href="" ng-click="$ctrl.proxima()"><i class="fas fa-chevron-right fa-lg text-white ml-3" title="Próxima página"></i></a>
			</div>
		</div>
	`,
  bindings: {
    itens: '<',
    itensVisualizados: '=visualizados',
  },
  controller() {
    this.$onChanges = () => {
      this.paginaAtual = 0;
      this.itensPorPagina = 10;
      this.start = 0;
      this.exibirPagina(this.paginaAtual, this.itensPorPagina);
    };

    this.exibirPagina = (pagina, itensPorPagina) => {
      this.start = pagina * itensPorPagina;

      this.itensVisualizados = this.itens.slice(this.start, itensPorPagina * (pagina + 1));
    };

    this.voltar = () => {
      if (this.paginaAtual === 0) {
        return;
      }

      this.paginaAtual -= 1;
      this.exibirPagina(this.paginaAtual, this.itensPorPagina);
    };

    this.proxima = () => {
      if (this.paginaAtual >= this.itens.length / this.itensPorPagina - 1) {
        return;
      }

      this.paginaAtual += 1;
      this.exibirPagina(this.paginaAtual, this.itensPorPagina);
    };

    this.exibirItens = () => {
      this.paginaAtual = 0;
      this.exibirPagina(this.paginaAtual, this.itensPorPagina);
    };
  },
});
