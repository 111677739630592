'use strict';

angular.module('app').service('Usuarios', UsuariosService);

function UsuariosService($http) {
  this.query = () => $http.get('/api/users').then(({ data }) => data);

  this.save = (usuario) => {
    const id = usuario._id;

    return $http.post(`/api/users/${id || ''}`, usuario).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/users/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/users/${id}`).then(({ data }) => data);
}
