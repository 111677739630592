'use strict';

import './editar-fabricante';
import './fabricante';
import './fabricantes.service';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/fabricantes', {
      template: '<fabricantes>',
    })
    .when('/fabricantes/novo', {
      template: '<editar-fabricante>',
    })
    .when('/fabricantes/:id', {
      template: '<fabricante>',
    })
    .when('/fabricantes/:id/editar', {
      template: '<editar-fabricante>',
    });
});

m.component('fabricantes', {
  templateUrl: 'fabricantes.html',
  controller(FabricantesTecido, $location, $filter) {
    this.$onInit = () => {
      this.carregando = true;
      this.fabricantes = [];
      this.fabricantesVisualizados = [];
      this.fabricantesFiltrados = [];
      this.ordenacao = 'name';
      this.query();
    };

    this.query = () => {
      FabricantesTecido.query().then((fabricantes) => {
        this.fabricantes = $filter('orderBy')(fabricantes, this.ordenacao);
        this.carregando = false;
        this.fabricantesFiltrados = this.fabricantes;
      });
    };

    this.novo = () => {
      $location.path('fabricantes/novo');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.fabricantesFiltrados = $filter('filter')(this.fabricantes, {
        name: this.filtroNome || undefined,
        contact: { name: this.filtroContato || undefined },
      });
    };

    this.ordenar = () => {
      this.fabricantes = $filter('orderBy')(this.fabricantes, this.ordenacao);
      this.atualizarFiltros();
    };
  },
});
