'use strict';

angular.module('app').component('modelo', {
  templateUrl: 'modelo.html',
  controller(Models, $routeParams, $location, $window) {
    this.$onInit = () => {
      Models.get($routeParams.id).then((modelo) => {
        this.modelo = modelo;
      });
    };

    this.editar = () => {
      $location.path(`modelos/${this.modelo._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este modelo?')) {
        Models.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
