const m = angular.module('app');

m.component('exibirSvg', {
  bindings: {
    node: '<',
  },
  controller($element) {
    const render = () => {
      const model = `
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:xml="http://www.w3.org/XML/1998/namespace" version="1.1" width="100%" height="300px" viewBox="0 0 0 0" class="modelo-svg">
				<g id="unfolding" style="visibility: visible;">
					${this.node || ''}
				</g>
			</svg>`;

      $element.empty();
      $element.append(model);

      const svgElement = $element.find('svg');
      const svgViewBox = svgElement[0].viewBox;
      const gUnfolding = svgElement[0].querySelector('g#unfolding');

      setTimeout(() => {
        const dimensoes = gUnfolding.getBBox();

        if (svgViewBox.baseVal) {
          svgViewBox.baseVal.x = dimensoes.x;
          svgViewBox.baseVal.y = dimensoes.y;
          svgViewBox.baseVal.height = dimensoes.height;
          svgViewBox.baseVal.width = dimensoes.width;
        }
      }, 0);
    };

    this.$onChanges = () => {
      render();
    };
  },
});
