'use strict';

angular.module('app').directive('markActiveRoute', ($location) => ({
  restrict: 'A',
  link(scope, elem) {
    markActive();

    scope.$on('$routeChangeSuccess', () => {
      markActive();
    });

    function markActive() {
      const href = $location.path();

      angular.forEach(elem.find('a'), (a) => {
        const aElm = angular.element(a);

        if (href.indexOf(aElm.attr('href').replace('/#!', '')) >= 0) {
          aElm.addClass('active');
        } else {
          aElm.removeClass('active');
        }
      });
    }
  },
}));
