angular
  .module('app')
  .service('GlobalErrors', function ($timeout, $rootScope) {
    let errors = [];

    let nextId = 0;

    this.showError = (message, type = 'danger', validations) => {
      nextId += 1;
      const id = nextId;

      errors.push({
        id,
        message,
        validations,
        type,
      });

      $rootScope.$broadcast('global-errors-change', errors);

      $timeout(() => {
        errors = errors.filter((error) => error.id !== id);
        $rootScope.$broadcast('global-errors-change', errors);
      }, 5000);
    };
  })
  .component('globalErrors', {
    template: `
			<div class="alert" ng-class="{'danger':'alert-danger'}[error.type]" ng-repeat="error in $ctrl.errors">
				{{error.message}}
				<ul ng-if="error.validations">
					<li ng-repeat="validation in error.validations">{{validation.field}}: {{validation.message}}</li>
				</ul>
			</div>
		`,
    controller($rootScope) {
      this.$onInit = () => {
        this.errors = [];

        $rootScope.$on('global-errors-change', (_, errors) => {
          this.errors = errors;
        });
      };
    },
  })
  .config(($httpProvider) => {
    $httpProvider.interceptors.unshift(errorsInterceptor);
  });

function errorsInterceptor($q, GlobalErrors) {
  return {
    responseError(response) {
      let errorMessage = response.data;

      let errorValidations;

      if (typeof response.data !== 'string') {
        errorMessage = response.data.message;
        errorValidations = response.data.validations;
      }

      const error = new Error(errorMessage);

      error.status = response.status;

      if (![401].includes(error.status)) {
        GlobalErrors.showError(errorMessage, 'danger', errorValidations);
      }

      return $q.reject(error);
    },
  };
}

errorsInterceptor.$inject = ['$q', 'GlobalErrors'];
