'use strict';

angular.module('app').component('editarSubgrupoTecido', {
  templateUrl: 'editar-subgrupo-tecido.html',
  controller(FabricSubgroups, FabricGroups, Models, $routeParams, $window) {
    this.$onInit = () => {
      this.subgrupoTecido = {};
      this.subgrupoTecido.model_ids = [];
      this.subgrupoTecido.models = [];

      if ($routeParams.id) {
        FabricSubgroups.get($routeParams.id).then((subgrupoTecido) => {
          this.subgrupoTecido = subgrupoTecido;
        });
      }

      Models.query().then((modelos) => {
        this.modelos = modelos;
        this.modelosFiltrados = modelos;
      });

      FabricGroups.query().then((grupos) => {
        this.grupos = grupos;
      });
    };

    this.salvar = () => {
      FabricSubgroups.save(this.subgrupoTecido).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.incluirModelo = () => {
      if (!this.modeloSelected) {
        return;
      }

      this.subgrupoTecido.model_ids.push(this.modeloSelected._id);
      this.subgrupoTecido.models.push(this.modeloSelected);

      const index = this.modelosFiltrados.findIndex(({ _id }) => _id === this.modeloSelected._id);

      this.modelosFiltrados.splice(index, 1);
    };

    this.removeModelo = (index) => {
      this.subgrupoTecido.model_ids.splice(index, 1);
      this.subgrupoTecido.models.splice(index, 1);
    };
  },
});
