'use strict';

angular.module('app').service('FabricGroups', function ($http) {
  this.query = () => $http.get('/api/fabric-groups').then(({ data }) => data);

  this.get = (id) => $http.get(`/api/fabric-groups/${id}`).then(({ data }) => data);

  this.save = (fabricGroup) => {
    const id = fabricGroup._id;

    return $http.post(`/api/fabric-groups/${id || ''}`, fabricGroup).then(({ data }) => data);
  };

  this.remove = (id) => $http.delete(`/api/fabric-groups/${id}`).then(({ data }) => data);
});
