'use strict';

angular.module('app').directive('openSidebar', () => ({
  restrict: 'A',
  link(scope, element, attrs) {
    element.bind('click', () => {
      angular.element(document.querySelector(attrs.openSidebar)).toggleClass('active');
    });
  },
}));
