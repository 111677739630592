'use strict';

import './editar-usuario.js';
import './usuario.js';
import './usuarios.service.js';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/usuarios', {
      template: '<usuarios>',
    })
    .when('/usuarios/novo', {
      template: '<editar-usuario>',
    })
    .when('/usuarios/:id', {
      template: '<usuario>',
    })
    .when('/usuarios/:id/editar', {
      template: '<editar-usuario>',
    });
});

m.component('usuarios', {
  templateUrl: 'usuarios.html',
  controller(Usuarios, $location, $filter) {
    this.$onInit = () => {
      this.usuarios = [];
      this.usuariosVisualizados = [];
      this.usuariosFiltrados = [];
      this.carregando = true;
      this.ordenacao = 'name';
      this.query();
    };

    this.query = () => {
      Usuarios.query().then((usuarios) => {
        this.usuarios = $filter('orderBy')(usuarios, this.ordenacao);
        this.carregando = false;
        this.usuariosFiltrados = this.usuarios;
      });
    };

    this.novo = () => {
      $location.path('usuarios/novo');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.usuariosFiltrados = $filter('filter')(this.usuarios, {
        name: this.filtroNome || undefined,
        email: this.filtroEmail || undefined,
      });
    };

    this.ordenar = () => {
      this.usuarios = $filter('orderBy')(this.usuarios, this.ordenacao);
      this.atualizarFiltros();
    };
  },
});
