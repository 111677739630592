'use strict';

angular.module('app').component('variacaoModelo', {
  templateUrl: 'variacao-modelo.html',
  controller(VariacoesModelo, $routeParams, $location, $window) {
    this.$onInit = () => {
      VariacoesModelo.get($routeParams.id).then((variacao) => {
        this.variacao = variacao;
      });
    };

    this.editar = () => {
      $location.path(`variacoes-modelo/${this.variacao._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir esta variação de tecido?')) {
        VariacoesModelo.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
