'use strict';

import 'angular';
import 'angular-route';
import 'angular-i18n/angular-locale_pt-br.js';
import 'angular-input-masks';
import 'angular-br-filters';

import './app.scss';

import './app.module';
import './auth/auth';
import './components/steppers';
import './components/paginacao';
import './components/global-errors';
import './configuracoes-app/configuracoes-app';
import './directives/open-sidebar';
import './directives/mark-active-route';
import './directives/file-upload';
import './directives/form-validation';
import './filters';
import './administradores/administradores';
import './amostras-tecido/amostras-tecido';
import './cupons-desconto/cupons-desconto';
import './fabricantes/fabricantes';
import './grupos-tecido/grupos-tecido';
import './leads/leads';
import './modelos/modelos';
import './pedidos/pedidos';
import './subgrupos-tecido/subgrupos-tecido';
import './usuarios/usuarios';
import './variacoes-modelo/variacoes-modelo';
import './tabelas-medidas/tabelas-medidas';
import './tecidos/tecidos';
import './tipos-modelo/tipos-modelo';

const m = angular.module('app');

m.config(($routeProvider, $locationProvider, $httpProvider) => {
  $routeProvider.otherwise({
    redirectTo: 'pedidos',
  });

  $httpProvider.useApplyAsync(true);
});

m.run((Auth) => {
  Auth.getSession();
});

m.factory(
  '$exceptionHandler',
  ($log) =>
    function myExceptionHandler(exception, cause) {
      if (/40[13]/.test(exception.status)) {
        return;
      }

      $log.error(exception, cause);
    }
);

m.component('mainLayout', {
  template: `
  <nav class="navbar navbar-expand navbar-light bg-light fixed-top">
      <button
        type="button"
        class="btn btn-outline-primary mr-2 d-sm-none"
        open-sidebar=".row-offcanvas"
        aria-label="Toggle sidebar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <a class="navbar-brand" href="#">
        <img src="/assets/logo-horizontal-3.png" height="25px" alt="Modacad" />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#mainMenu"
        aria-controls="mainMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="mainMenu">
        <ul class="navbar-nav ml-auto">
          <li>
            <a class="nav-link" href="" logout><i class="fas fa-lg fa-fw fa-sign-out-alt"></i></a>
          </li>
        </ul>
      </div>
    </nav>

    <main role="main" class="container-fluid">
      <div class="row row-offcanvas row-offcanvas-left">
        <div ng-if="$ctrl.showSidebar" class="col-6 col-md-4 col-lg-3 sidebar-offcanvas d-print-none" id="sidebar">
          <div class="list-group" mark-active-route>
            <a class="list-group-item" href="/#!/home">Home</a>
            <a class="list-group-item" href="/#!/pedidos">Pedidos</a>
            <a class="list-group-item" href="/#!/cupons-desconto">Cupons de Desconto</a>
            <a class="list-group-item" href="/#!/leads">Leads</a>
            <a class="list-group-item" href="/#!/tipos-modelo">Tipos de Modelo</a>
            <a class="list-group-item" href="/#!/modelos">Modelos</a>
            <a class="list-group-item" href="/#!/variacoes-modelo">Variações de modelo</a>
            <a class="list-group-item" href="/#!/grupos-tecido">Grupos de Tecido</a>
            <a class="list-group-item" href="/#!/subgrupos-tecido">Subgrupos de Tecido</a>
            <a class="list-group-item" href="/#!/tecidos">Tecidos</a>
            <a class="list-group-item" href="/#!/fabricantes">Fabricantes de tecido</a>
            <a class="list-group-item" href="/#!/amostras-tecido">Amostras de tecido</a>
            <a class="list-group-item" href="/#!/tabelas-medidas">Tabelas de Medidas</a>
            <a class="list-group-item" href="/#!/configuracoes-app">Configurações do App</a>
            <a class="list-group-item" href="/#!/usuarios">Usuários</a>
            <a class="list-group-item" href="/#!/administradores">Administradores</a>
          </div>
        </div>
        <div class="col-12 full-width-print" ng-class="$ctrl.contentClasses" ng-view></div>
      </div>
    </main>

  <footer class="footer d-print-none">
    <div class="container-fluid">
      <span class="text-muted"
        >© 2017 <a href="http://www.modacad.com.br">Modacad</a>. Todos os direitos reservados. Versão atual:
        1.2.1</span
      >
    </div>
  </footer>

  <global-errors></global-errors>
  `,
  controller(Auth) {
    this.$onInit = () => {
      Auth.subscribe((isAuthenticated) => {
        this.showSidebar = isAuthenticated;
        this.contentClasses = isAuthenticated ? 'col-md-8 col-lg-9' : '';
      });
    };
  },
});
