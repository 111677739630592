angular.module('app').component('editarTipoModelo', {
  templateUrl: 'editar-tipo-modelo.html',
  controller(ModelTypes, $window, $routeParams) {
    this.$onInit = () => {
      this.tipoModelo = {};

      if ($routeParams.id) {
        ModelTypes.get($routeParams.id).then((tipoModelo) => {
          this.tipoModelo = tipoModelo;
        });
      }
    };

    this.salvar = () => {
      ModelTypes.save(this.tipoModelo).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
