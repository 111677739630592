angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider.when('/login', {
      template: '<login>',
    });
  })
  .component('login', {
    templateUrl: 'login.html',
    controller(Auth, $location) {
      this.$onInit = () => {
        this.reset();
        Auth.getSession().then(() => {
          $location.path('/');
        });
      };

      this.reset = () => {
        this.loginInvalido = false;
      };

      this.login = () => {
        Auth.login(this.credenciais).then(
          () => {
            $location.path('/');
          },
          () => {
            this.loginInvalido = true;
          }
        );
      };
    },
  });
