'use strict';

import './grupos-tecido.service';
import './grupo-tecido';
import './editar-grupo-tecido';

angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider
      .when('/grupos-tecido', {
        template: '<grupos-tecido>',
      })
      .when('/grupos-tecido/novo', {
        template: '<editar-grupo-tecido>',
      })
      .when('/grupos-tecido/:id/editar', {
        template: '<editar-grupo-tecido>',
      })
      .when('/grupos-tecido/:id', {
        template: '<grupo-tecido>',
      });
  })
  .component('gruposTecido', {
    templateUrl: 'grupos-tecido.html',
    controller(FabricGroups, $location, $filter) {
      this.$onInit = () => {
        this.gruposTecido = [];
        this.gruposTecidoVisualizados = [];
        this.gruposTecidoFiltrados = [];
        this.carregando = true;
        this.ordenacao = 'order';
        this.query();
      };

      this.query = () => {
        FabricGroups.query().then((gruposTecido) => {
          this.gruposTecido = $filter('orderBy')(gruposTecido, this.ordenacao);
          this.carregando = false;
          this.gruposTecidoFiltrados = this.gruposTecido;
        });
      };

      this.novo = () => {
        $location.path('/grupos-tecido/novo');
      };

      this.exibirFiltros = () => {
        this.exibeFiltros = !this.exibeFiltros;
      };

      this.atualizarFiltros = () => {
        this.gruposTecidoFiltrados = $filter('filter')(this.gruposTecido, {
          name: this.filtroNome || undefined,
          order: this.filtroOrdem || undefined,
        });
      };

      this.ordenar = () => {
        this.gruposTecido = $filter('orderBy')(this.gruposTecido, this.ordenacao);
        this.atualizarFiltros();
      };
    },
  });
