'use strict';

angular.module('app').component('editarTecido', {
  templateUrl: 'editar-tecido.html',
  controller(Fabrics, FabricGroups, FabricSubgroups, $routeParams, $window, $filter, $q) {
    this.$onInit = () => {
      this.tecido = {};

      if ($routeParams.id) {
        Fabrics.get($routeParams.id).then((tecido) => {
          this.tecido = tecido;
          this.subgrupoEscolhido = this.tecido.subgroup;
        });
      }

      this.grupos = [];

      $q.all([FabricGroups.query(), FabricSubgroups.query()]).then(([grupos, subgrupos]) => {
        this.grupos = $filter('orderBy')(grupos, 'name');
        this.subgrupos = $filter('orderBy')(subgrupos, 'name');
        this.filtrarSubgrupos();
      });
    };

    this.salvar = () => {
      const index = this.subgruposDisponiveis.findIndex((subgrupo) => subgrupo._id === this.tecido.subgroup_id);

      this.tecido.subgroup = this.subgruposDisponiveis[index].name;

      Fabrics.save(this.tecido).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.filtrarSubgrupos = () => {
      if (!this.tecido.group_id) {
        return;
      }

      this.subgruposDisponiveis = this.subgrupos.filter((subgrupo) => this.tecido.group_id.includes(subgrupo.group_id));
    };
  },
});
