'use strict';

import './editar-amostra-tecido.js';
import './amostra-tecido.js';
import './amostras-tecido.service.js';

const m = angular.module('app');

m.config(($routeProvider) => {
  $routeProvider
    .when('/amostras-tecido', {
      template: '<amostras-tecido>',
    })
    .when('/amostras-tecido/novo', {
      template: '<editar-amostra-tecido>',
    })
    .when('/amostras-tecido/:id', {
      template: '<amostra-tecido>',
    })
    .when('/amostras-tecido/:id/editar', {
      template: '<editar-amostra-tecido>',
    });
});

m.component('amostrasTecido', {
  templateUrl: 'amostras-tecido.html',
  controller(AmostrasTecido, $location, $filter) {
    this.$onInit = () => {
      this.carregando = true;
      this.amostras = [];
      this.amostrasVisualizadas = [];
      this.amostrasFiltradas = [];
      this.ordenacao = 'fabric.subgroup';
      this.query();
    };

    this.query = () => {
      AmostrasTecido.query().then((amostras) => {
        this.amostras = $filter('orderBy')(amostras, this.ordenacao);
        this.carregando = false;
        this.amostrasFiltradas = this.amostras;
      });
    };

    this.novo = () => {
      $location.path('amostras-tecido/novo');
    };

    this.exibirFiltros = () => {
      this.exibeFiltros = !this.exibeFiltros;
    };

    this.atualizarFiltros = () => {
      this.amostrasFiltradas = $filter('filter')(this.amostras, {
        name: this.filtroNome || undefined,
        fabric: { subgroup: this.filtroSubgrupo },
      });
    };

    this.ordenar = () => {
      this.amostras = $filter('orderBy')(this.amostras, this.ordenacao);
      this.atualizarFiltros();
    };
  },
});
