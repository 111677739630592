angular.module('app').service('CuponsDesconto', function ($http, $httpParamSerializer) {
  this.query = (filtros) =>
    $http
      .get(`/api/cupons-desconto${Object.keys(filtros || {}).length > 0 ? `?${$httpParamSerializer(filtros)}` : ''}`)
      .then(({ data }) => data);

  this.get = (id) => $http.get(`/api/cupons-desconto/${id}`).then(({ data }) => data);

  this.save = (cupom) => {
    const id = cupom._id;

    return $http.post(`/api/cupons-desconto/${id || ''}`, cupom).then(({ data }) => data);
  };

  this.remove = (id) => $http.delete(`/api/cupons-desconto/${id}`).then(({ data }) => data);
});
