'use strict';

angular.module('app').component('grupoTecido', {
  templateUrl: 'grupo-tecido.html',
  controller(FabricGroups, FabricSubgroups, $routeParams, $location, $window) {
    this.$onInit = () => {
      FabricGroups.get($routeParams.id).then((grupoTecido) => {
        this.grupoTecido = grupoTecido;
      });

      FabricSubgroups.query({
        group_id: $routeParams.id,
      }).then((subgrupos) => {
        this.subgrupos = subgrupos;
      });
    };

    this.editar = () => {
      $location.path(`/grupos-tecido/${this.grupoTecido.id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este grupo de tecido?')) {
        FabricGroups.remove(this.grupoTecido._id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
