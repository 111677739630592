'use strict';

angular.module('app').service('AmostrasTecido', function ($http, $httpParamSerializer) {
  this.query = (filtros) =>
    $http
      .get(`/api/amostras-tecido/${Object.keys(filtros || {}).length > 0 ? `?${$httpParamSerializer(filtros)}` : ''}`)
      .then(({ data }) => data);

  this.save = (amostra) => {
    const id = amostra._id;

    return $http.post(`/api/amostras-tecido/${id || ''}`, amostra).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/amostras-tecido/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/amostras-tecido/${id}`).then(({ data }) => data);

  this.uploadImagem = (id, image) =>
    $http
      .post(`/api/amostras-tecido/${id}/imagens`, image, {
        headers: { 'Content-type': undefined },
      })
      .then(({ data }) => data);

  this.deleteImagem = (id) => $http.delete(`api/amostras-tecido/${id}/imagens`).then(({ data }) => data);
});
