'use strict';

angular.module('app').component('editarVariacaoModelo', {
  templateUrl: 'editar-variacao-modelo.html',
  controller(VariacoesModelo, ModelTypes, $routeParams, $window, $filter) {
    this.$onInit = () => {
      const { id } = $routeParams;

      this.editingMode = false;

      this.variacao = {
        labels: [],
      };

      if (id) {
        VariacoesModelo.get(id).then((variacao) => {
          this.variacao = variacao;
        });
      }

      ModelTypes.query().then((tiposModelo) => {
        this.tiposModelo = $filter('orderBy')(tiposModelo, 'name');
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.salvar = () => {
      VariacoesModelo.save(this.variacao).then(() => {
        this.voltar();
      });
    };

    this.newLabel = () => {
      const label = {
        $editMode: true,
      };

      this.editingMode = true;
      this.variacao.labels.push(label);
    };

    this.closeEditMode = (label) => {
      label.$editMode = false;
      this.editingMode = false;
    };

    this.editLabel = (label) => {
      this.editingMode = true;
      label.$editMode = true;
    };

    this.removeLabel = (index) => {
      this.variacao.labels.splice(index, 1);
    };
  },
});
