angular.module('app').component('editarConfiguracoesApp', {
  templateUrl: 'editar-configuracoes-app.html',
  controller(ConfiguracoesAppService, $window, $routeParams) {
    this.$onInit = () => {
      this.configuracoes = {};

      ConfiguracoesAppService.get($routeParams.id).then((configuracoes) => {
        this.configuracoes = configuracoes;
      });
    };

    this.salvar = () => {
      ConfiguracoesAppService.save(this.configuracoes).then(() => {
        this.voltar();
      });
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
