'use strict';

angular.module('app').component('editarGruposSubgrupos', {
  bindings: {
    grupos: '<',
    subgrupos: '<',
    modelo: '<',
  },
  templateUrl: 'editar-grupos-subgrupos.html',
  controller() {
    this.subgruposFiltrados = [];
    this.gruposFiltrados = [];
    let subgruposDisponiveis = [];

    this.modelo = {};

    this.$onChanges = () => {
      if (!this.modelo.group_ids) {
        this.modelo.group_ids = [];
      }

      if (!this.modelo.groups) {
        this.modelo.groups = [];
      }

      if (!this.modelo.subgroup_ids) {
        this.modelo.subgroup_ids = [];
      }

      if (!this.modelo.subgroups) {
        this.modelo.subgroups = [];
      }

      this.filtrarGrupos();
      this.filtrarSubgrupos();
    };

    this.filtrarSubgrupos = () => {
      subgruposDisponiveis = this.subgrupos.filter((subgrupo) => this.modelo.group_ids.includes(subgrupo.group_id));

      this.subgruposFiltrados = subgruposDisponiveis.filter(({ _id }) => !this.modelo.subgroup_ids.includes(_id));
    };

    this.filtrarGrupos = () => {
      this.gruposFiltrados = this.grupos.filter(({ _id }) => !this.modelo.group_ids.includes(_id));
    };

    this.incluirGrupo = () => {
      if (!this.grupoSelected) {
        return;
      }

      this.modelo.groups.push(this.grupoSelected);
      this.modelo.group_ids.push(this.grupoSelected.id);
      this.filtrarGrupos();
      this.filtrarSubgrupos();
    };

    this.removeGrupo = (index) => {
      if (this.modelo.group_ids.length === 1) {
        window.confirm('Erro! É necessário haver pelo menos um grupo de tecido para cada modelo');

        return;
      }

      this.modelo.group_ids.splice(index, 1);
      this.modelo.groups.splice(index, 1);
      this.filtrarGrupos();
      this.filtrarSubgrupos();
    };

    this.incluirSubgrupo = () => {
      if (!this.subgrupoSelected) {
        return;
      }

      this.modelo.subgroup_ids.push(this.subgrupoSelected._id);
      this.modelo.subgroups.push(this.subgrupoSelected);
      this.filtrarSubgrupos();
    };

    this.removeSubgrupo = (index) => {
      if (this.modelo.subgroup_ids.length === 1) {
        window.confirm('Erro! É necessário haver pelo menos um subgrupo de tecido para cada modelo');

        return;
      }

      this.modelo.subgroup_ids.splice(index, 1);
      this.modelo.subgroups.splice(index, 1);
      this.filtrarSubgrupos();
    };
  },
});
