'use strict';

angular.module('app').component('editarAdministrador', {
  templateUrl: 'editar-administrador.html',
  controller(Administradores, $routeParams, $window) {
    this.$onInit = () => {
      const { id } = $routeParams;

      this.administrador = {};
      if (id) {
        Administradores.get(id).then((administrador) => {
          this.administrador = administrador;
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.salvar = () => {
      Administradores.save(this.administrador).then(() => {
        this.voltar();
      });
    };
  },
});
