'use strict';

angular.module('app').component('lead', {
  templateUrl: 'lead.html',
  controller(Leads, $routeParams, $window) {
    this.$onInit = () => {
      Leads.get($routeParams.id).then((lead) => {
        this.lead = lead;
      });
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
