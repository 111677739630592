'use strict';

angular.module('app').service('Fabrics', function ($http, $httpParamSerializer) {
  this.query = (filtros) =>
    $http
      .get(`/api/fabrics/${Object.keys(filtros || {}).length > 0 ? `?${$httpParamSerializer(filtros)}` : ''}`)
      .then(({ data }) => data);
  this.save = (fabric) => {
    const id = fabric._id;

    return $http.post(`/api/fabrics/${id || ''}`, fabric).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/fabrics/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/fabrics/${id}`).then(({ data }) => data);

  this.uploadImagem = (id, image) =>
    $http
      .post(`/api/fabrics/${id}/imagens`, image, {
        headers: { 'Content-type': undefined },
      })
      .then(({ data }) => data);
});
