'use strict';

angular.module('app').component('subgrupoTecido', {
  templateUrl: 'subgrupo-tecido.html',
  controller(FabricSubgroups, Fabrics, $routeParams, $location, $window) {
    this.$onInit = () => {
      FabricSubgroups.get($routeParams.id).then((subgrupoTecido) => {
        this.subgrupoTecido = subgrupoTecido;
      });

      Fabrics.query({
        subgroup_id: $routeParams.id,
      }).then((tecidos) => {
        this.tecidos = tecidos;
      });
    };

    this.editar = () => {
      $location.path(`/subgrupos-tecido/${this.subgrupoTecido._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este subgrupo de tecido?')) {
        FabricSubgroups.remove(this.subgrupoTecido._id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
