'use strict';

angular.module('app').component('editarModelo', {
  templateUrl: 'editar-modelo.html',
  controller(Models, FabricGroups, FabricSubgroups, ModelTypes, $window, $routeParams, $filter) {
    this.$onInit = () => {
      const { id } = $routeParams;

      this.modelo = {};
      this.grupos = [];
      this.subgrupos = [];

      if (id) {
        Models.get(id).then((modelo) => {
          this.modelo = modelo;
        });
      }

      FabricGroups.query().then((grupos) => {
        this.grupos = $filter('orderBy')(grupos, 'name');
      });

      FabricSubgroups.query().then((subgrupos) => {
        this.subgrupos = $filter('orderBy')(subgrupos, 'name');
      });

      ModelTypes.query().then((tipos) => {
        this.tipos = $filter('orderBy')(tipos, 'name');
      });
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.salvar = () => {
      Models.save(this.modelo).then(() => {
        this.voltar();
      });
    };
  },
});
