'use strict';

angular.module('app').service('VariacoesModelo', function ($http) {
  this.query = () => $http.get('/api/variacoes-modelo').then(({ data }) => data);

  this.save = (variacao) => {
    const id = variacao._id;

    return $http.post(`/api/variacoes-modelo/${id || ''}`, variacao).then(({ data }) => data);
  };

  this.get = (id) => $http.get(`/api/variacoes-modelo/${id}`).then(({ data }) => data);

  this.remove = (id) => $http.delete(`/api/variacoes-modelo/${id}`).then(({ data }) => data);
});
