import './editar-tabela-medidas';
import './tabela-medidas';
import './tabelas-medidas.service';

angular
  .module('app')
  .config(($routeProvider) => {
    $routeProvider
      .when('/tabelas-medidas', {
        template: '<tabelas-medidas>',
      })
      .when('/tabelas-medidas/nova', {
        template: '<editar-tabela-medidas>',
      })
      .when('/tabelas-medidas/:id/editar', {
        template: '<editar-tabela-medidas>',
      })
      .when('/tabelas-medidas/:id', {
        template: '<tabela-medidas>',
      });
  })
  .component('tabelasMedidas', {
    templateUrl: 'tabelas-medidas.html',
    controller(MeasuresTables, $location, $filter) {
      this.$onInit = () => {
        this.carregando = true;
        this.tabelas = [];
        this.tabelasVisualizadas = [];
        this.tabelasFiltradas = [];
        this.query();
      };

      this.query = () => {
        MeasuresTables.query().then((tabelas) => {
          this.tabelas = $filter('orderBy')(tabelas, 'name');
          this.carregando = false;
          this.tabelasFiltradas = this.tabelas;
        });
      };

      this.nova = () => {
        $location.path('/tabelas-medidas/nova');
      };

      this.exibirFiltros = () => {
        this.exibeFiltros = !this.exibeFiltros;
      };

      this.atualizarFiltros = () => {
        this.tabelasFiltradas = $filter('filter')(this.tabelas, {
          name: this.filtroNome || undefined,
        });
      };
    },
  });
