'use strict';

angular.module('app').component('tecido', {
  templateUrl: 'tecido.html',
  controller(Fabrics, AmostrasTecido, $routeParams, $location, $window) {
    this.$onInit = () => {
      this.get();
    };

    this.get = () => {
      Fabrics.get($routeParams.id).then((tecido) => {
        this.tecido = tecido;
      });

      AmostrasTecido.query({
        fabric_id: $routeParams.id,
      }).then((amostras) => {
        this.amostras = amostras;
      });
    };

    this.editar = () => {
      $location.path(`/tecidos/${this.tecido._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deseja excluir este tecido?')) {
        Fabrics.remove(this.tecido._id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };

    this.incluiImagem = (arquivos) => {
      this.carregandoFoto = true;
      const formData = new FormData();

      formData.append('imagemOriginal', arquivos[0], arquivos[0].name);

      Fabrics.uploadImagem(this.tecido._id, formData).then(() => {
        this.carregandoFoto = false;
        this.get();
      });
    };

    this.toggleHabilitar = () => {
      this.tecido.disabled = !this.tecido.disabled;
      Fabrics.save(this.tecido);
    };
  },
});
