'use strict';

angular.module('app').component('administrador', {
  templateUrl: 'administrador.html',
  controller(Administradores, $routeParams, $location, $window) {
    this.$onInit = () => {
      Administradores.get($routeParams.id).then((administrador) => {
        this.administrador = administrador;
      });
    };

    this.editar = () => {
      $location.path(`administradores/${this.administrador._id}/editar`);
    };

    this.excluir = () => {
      if (confirm('Deja excluir este administrador?')) {
        Administradores.remove($routeParams.id).then(() => {
          this.voltar();
        });
      }
    };

    this.voltar = () => {
      $window.history.back();
    };
  },
});
