'use strict';

import './importacao-modelo';

angular.module('app').component('importacoes', {
  templateUrl: 'importacao.html',
  controller(Models, FabricGroups, FabricSubgroups, $filter, $location, $q) {
    this.$onInit = () => {
      this.exibirForm = false;

      FabricGroups.query().then((grupos) => {
        this.grupos = $filter('orderBy')(grupos, 'name');
      });

      FabricSubgroups.query().then((subgrupos) => {
        this.subgrupos = $filter('orderBy')(subgrupos, 'name');
      });
    };

    this.importar = (arquivos) => {
      this.carregandoSVG = true;
      const formData = new FormData();

      formData.append('svg', arquivos[0], arquivos[0].name);
      Models.importar(formData).then((importacoes) => {
        if (importacoes) {
          this.importacoes = importacoes;
          this.exibirForm = true;
        }
      });
    };

    this.salvar = () => {
      $q.all(
        this.importacoes.map((importacao) => {
          if (!importacao.habilitada) {
            return;
          }

          return Models.saveVariation(importacao);
        })
      ).then(() => {
        $location.path('/modelos');
      });
    };
  },
});
