angular
  .module('app')
  .component('tipoModelo', {
    templateUrl: 'tipo-modelo.html',
    controller(ModelTypes, Models, $window, $routeParams, $location, $q) {
      this.$onInit = () => {
        this.query();
      };

      this.query = () => {
        ModelTypes.get($routeParams.id).then((tipoModelo) => {
          this.tipoModelo = tipoModelo;
        });

        Models.query({
          type_id: $routeParams.id,
        }).then((modelos) => {
          this.modelos = modelos;
        });
      };

      this.voltar = () => {
        $window.history.back();
      };

      this.editar = () => {
        $location.path(`/tipos-modelo/${this.tipoModelo._id}/editar`);
      };

      this.excluir = () => {
        if (confirm('Deseja excluir este tipo de modelo?')) {
          ModelTypes.remove(this.tipoModelo._id).then(() => {
            this.voltar();
          });
        }
      };

      this.definirRepresentante = (modelo) => {
        this.tipoModelo.representative = modelo._id;
        modelo.represented_id = this.tipoModelo._id;

        $q.all([ModelTypes.save(this.tipoModelo), Models.save(modelo)]).then(() => {
          this.query();
        });
      };
    },
  })
  .directive('isolateClick', () => ({
    link(scope, elem) {
      elem.on('click', (e) => {
        e.preventDefault();
      });
    },
  }));
